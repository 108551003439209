<template>
    <landing :breadCrumbs="breadCrumbs" :shopName="shopName">
        <v-container>
            <v-row
                id="description"
                justify="center"
                :class="smAndUp ? 'mb-15' : null"
            >
                <v-col cols="auto" align="center">
                    <heading
                        ><span class="red--text">南</span>行徳新井店</heading
                    >
                </v-col>
            </v-row>

            <!-- 紹介文 -->
            <v-row justify="end">
                <v-col cols="12" md="6">
                    <div :class="smAndUp ? 'text-h4' : 'text-body-1'">
                        <span class="red--text">アクセス</span>:<span
                            :class="smAndUp ? 'text-h6' : 'text-body-2'"
                            >南行徳駅徒歩12分!</span
                        >
                    </div>
                    <div :class="smAndUp ? 'text-h6' : 'text-body-2'">
                        <span class="red--text">住所</span>:<span
                            :class="smAndUp ? 'text-body-1' : 'text-caption'"
                            >千葉県市川市新井2丁目5-13</span
                        >
                    </div>
                </v-col>
                <v-col cols="12" md="6">
                    <div :class="smAndUp ? 'text-h4' : 'text-body-1'">
                        <span class="red--text">営業時間</span>:<span
                            :class="smAndUp ? 'text-h6' : 'text-body-2'"
                            >平日13:00～22:00/土日10:00～18:00<br />※時間外は応相談</span
                        >
                    </div>
                    <div :class="smAndUp ? 'text-h6' : 'text-body-2'">
                        <span class="red--text">定休日</span>:<span
                            :class="smAndUp ? 'text-body-1' : 'text-caption'"
                            >火・土</span
                        >
                    </div>
                </v-col>
            </v-row>

            <!-- 代表イメージ -->
            <v-row justify="end">
                <v-col cols="12" md="6">
                    <v-card outlined style="border-style: none">
                        <v-img height="360px" :src="imageMv"></v-img>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card outlined style="border-style: none">
                        <v-img
                            position="bottom center"
                            height="360px"
                            :src="shopImage"
                        ></v-img>
                    </v-card>
                </v-col>
                <v-col v-if="!smAndUp" cols="12" align="center">
                    <v-icon
                        small
                        color="red darken-2"
                        @click="$vuetify.goTo('#target')"
                        >fas fa-chevron-down</v-icon
                    >
                    駅からのご案内
                    <v-divider></v-divider>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card outlined style="border-style: none">
                        <GmapMap
                            :center="center"
                            :zoom="zoom"
                            map-type-id="terrain"
                            style="width: 100%; height: 360px"
                        >
                            <GmapMarker
                                :key="index"
                                v-for="(m, index) in markers"
                                :position="m.position"
                                :clickable="true"
                                :draggable="true"
                                @click="center = m.position"
                            />
                        </GmapMap>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card outlined style="border-style: none">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!4v1717470665149!6m8!1m7!1su8VzzJeJ5bDa8pKva0PXHg!2m2!1d35.6755606833492!2d139.8950642741464!3f135.19537263774993!4f8.801638627632954!5f0.7820865974627469"
                            width="100%"
                            height="360"
                            style="border:0;"
                            allowfullscreen=""
                            loading="lazy"
                            referrerpolicy="no-referrer-when-downgrade"
                        ></iframe>
                    </v-card>
                </v-col>
            </v-row>

            <!-- 店舗個別メッセージ -->
            <shop-message :message="this.message" :shopImage="trainerImage" />

            <!-- ナビゲーション -->
            <v-row justify="center" :class="smAndUp ? 'mb-15' : null">
                <v-col cols="auto" align="center">
                    <heading
                        ><span id="target" class="red--text">南行徳駅</span
                        >からお越しの方</heading
                    >
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="6">
                    <v-card-title class="pl-0"
                        ><div class="navigation">
                            <span class="red--text text-md-h6">1</span>.
                            改札を出て右側のロータリー（北口側）にいきます。
                        </div></v-card-title
                    >
                    <v-card outlined style="border-style: none">
                        <v-img height="auto" :src="accessA"></v-img>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card-title class="pl-0"
                        ><div class="navigation">
                            <span class="red--text text-md-h6">2</span>.
                            階段を降りたら左（交番側）にいきます。
                        </div>
                    </v-card-title>
                    <v-card outlined style="border-style: none">
                        <v-img height="auto" :src="accessB"></v-img>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card-title class="pl-0"
                        ><div class="navigation">
                            <span
                                ><span class="red--text text-md-h6">3</span>.
                                マクドナルドの方へ信号を渡り、大通りの方へ進みます。</span
                            >
                        </div>
                    </v-card-title>
                    <v-card outlined style="border-style: none">
                        <v-img height="auto" :src="accessC"></v-img>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card-title class="pl-0"
                        ><div class="navigation">
                            <span class="red--text text-md-h6">4</span>.
                            マルエツやドコモショップ側へ大通りに沿って進みます。
                        </div>
                    </v-card-title>
                    <v-card outlined style="border-style: none">
                        <v-img height="auto" :src="accessD"></v-img>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card-title class="pl-0"
                        ><div class="navigation">
                            <span class="red--text text-md-h6">5</span>.
                            スエヒロ館の信号も大通りに沿ってまっすぐ進みます。
                        </div>
                    </v-card-title>
                    <v-card outlined style="border-style: none">
                        <v-img height="auto" :src="accessE"></v-img>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card-title class="pl-0"
                        ><div class="navigation">
                            <span class="red--text text-md-h6">6</span>.
                            auショップの信号も大通りに沿ってまっすぐ進みます。
                        </div>
                    </v-card-title>
                    <v-card outlined style="border-style: none">
                        <v-img height="auto" :src="accessF"></v-img>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card-title class="pl-0"
                        ><div class="navigation">
                            <span class="red--text text-md-h6">7</span>.
                            バイパスの交差点の信号も渡ります。
                        </div>
                    </v-card-title>
                    <v-card outlined style="border-style: none">
                        <v-img height="auto" :src="accessG"></v-img>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card-title class="pl-0"
                        ><div class="navigation">
                            <span class="red--text text-md-h6">8</span>.
                            歩道橋手前の信号で曲がってその道を進みます。
                        </div>
                    </v-card-title>
                    <v-card outlined style="border-style: none">
                        <v-img height="auto" :src="accessH"></v-img>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card-title class="pl-0"
                        ><div class="navigation">
                            <span class="red--text text-md-h6">9</span>.
                            道の左側にREVOIST南行徳新井店がございます。
                        </div>
                    </v-card-title>
                    <v-card outlined style="border-style: none">
                        <v-img height="auto" :src="accessI"></v-img>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>

        <footer-contents></footer-contents>
    </landing>
</template>

<script>
import Heading from '@/components/Heading.vue'
import FooterContents from '@/components/FooterContents.vue'
import ShopMessage from '@/components/landing/ShopMessage.vue'
import Landing from '@/views/Landing.vue'
export default {
    mounted() {
        document.title = `${this.$route.meta.title} | パーソナルトレーニングジム【REVOISTレボイスト】`
        document.querySelector('meta[name="description"]').setAttribute(
            'content',
            `パーソナルジム REVOISTの14号店です！
南行徳駅から北側に徒歩12分、バイパス側の住宅街のジムです！
南行徳の北側にお住いの方にとって通いやすい立地になります。
身体を変える為にはしっかりと継続する事が大切ですが、継続するにはまず楽しむことが必要です！
なのでお客様が楽しんで筋トレ出来て、ここにきて良かったなと感じてもらえる様に全力でサポートしたいと思います！
食事のことや私生活などなんでもご相談ください！
トレーニングを通して人生を変えるお手伝いをしたいと思います！！
`
        )
    },
    components: {
        Heading,
        FooterContents,
        ShopMessage,
        Landing,
    },
    data() {
        return {
            shopName: '南行徳新井店',
            center: {
                lat: 35.67565396479791,
                lng: 139.89517472605831,
            },
            zoom: 18,
            markers: [
                {
                    position: {
                        lat: 35.67565396479791,
                        lng: 139.89517472605831,
                    },
                    title: '南行徳新井店',
                },
            ],
            message: `パーソナルジム REVOISTの14号店です！<br />
南行徳駅から北側に徒歩12分、バイパス側の住宅街のジムです！南行徳の北側にお住いの方にとって通いやすい立地になります。<br /><br />

身体を変える為にはしっかりと継続する事が大切ですが、継続するにはまず楽しむことが必要です！<br />
なのでお客様が楽しんで筋トレ出来て、ここにきて良かったなと感じてもらえる様に全力でサポートしたいと思います！<br />
食事のことや私生活などなんでもご相談ください！<br />
トレーニングを通して人生を変えるお手伝いをしたいと思います！！

<br />
<br />
南行徳新井店トレーナー：栄門成世
`,
        }
    },
    computed: {
        smAndUp() {
            return this.$vuetify.breakpoint.smAndUp
        },
        breadCrumbs() {
            return [
                {
                    text: 'トップ',
                    disabled: false,
                    href: './',
                },
                {
                    text: '店舗一覧・アクセス',
                    disabled: false,
                    href: 'gyms',
                },
                {
                    text: '南行徳新井店',
                    disabled: true,
                    href: 'access-12',
                },
            ]
        },

        imageMv() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_access_14/minamigyotokuarai-10.png'
                : 'static/pc_access_14/minamigyotokuarai-10.png'
        },
        // Create a computed property for each image from A to I
        accessA() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_access_14/minamigyotokuarai-1.png'
                : 'static/pc_access_14/minamigyotokuarai-1.png'
        },
        accessB() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_access_14/minamigyotokuarai-2.png'
                : 'static/pc_access_14/minamigyotokuarai-2.png'
        },
        accessC() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_access_14/minamigyotokuarai-3.png'
                : 'static/pc_access_14/minamigyotokuarai-3.png'
        },
        accessD() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_access_14/minamigyotokuarai-4.png'
                : 'static/pc_access_14/minamigyotokuarai-4.png'
        },
        accessE() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_access_14/minamigyotokuarai-5.png'
                : 'static/pc_access_14/minamigyotokuarai-5.png'
        },
        accessF() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_access_14/minamigyotokuarai-6.png'
                : 'static/pc_access_14/minamigyotokuarai-6.png'
        },
        accessG() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_access_14/minamigyotokuarai-7.png'
                : 'static/pc_access_14/minamigyotokuarai-7.png'
        },
        accessH() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_access_14/minamigyotokuarai-8.png'
                : 'static/pc_access_14/minamigyotokuarai-8.png'
        },
        accessI() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_access_14/minamigyotokuarai-9.png'
                : 'static/pc_access_14/minamigyotokuarai-9.png'
        },

        shopImage() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_access_14/minamigyotokuarai-9.png'
                : 'static/pc_access_14/minamigyotokuarai-9.png'
        },
        trainerImage() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/landing/trainer/南行徳新井店 トレーナー.jpg'
                : 'static/landing/trainer/南行徳新井店 トレーナー.jpg'
        },
    },
}
</script>

<style lang="scss">
.navigation {
    min-height: 3em;
}
</style>
